.navbar{
    position: fixed;
    width: 100%;
    top: 0;
}

.navContainer {
    height: auto;
    display: flex;
    justify-content: center;
    padding: 12px 24px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: inset 0px -1px 0px #E0E0E0;
    backdrop-filter: blur(12px);
}

.navWrapper{
    width: 100%;
    max-width: 1128px;
    color: #212121;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo{
    width: 48px;
}

.navItems{
    display: flex;
    gap: 32px;
}

.navButton{
    margin-left: 16px;
    border-radius: 24px !important;
}

.contactInfoItem{
    display: flex;
    align-items: center;
    gap: 8px;
}

.contactInfoItem span{
    width: 32px;
    font-size: 20px;
    text-align: center;
    color: #212121;
}

.contactInfoPopup{
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    padding: 12px;
    border-radius: 16px !important;
}

/* Media queries */

@media screen and (max-width: 480px){
    .navContainer {
        flex-direction: column;
        gap: 16px;
    }
}