.header {
  display: flex;
  justify-content: center;
  padding: 0 24px;
  background: linear-gradient(180deg, #ffffff 0%, #f1f3f6 100%);
  margin-top: 76px;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 64px;
  width: 100%;
  max-width: 1128px;
  padding: 80px 0;
  border-bottom: 1px solid #e0e0e0;
}

.headerIntroduction {
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.headerHelloTxt {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #0068ff;
}

.headerIntroTxt {
  color: #212121;
}

.headerIntroTxt h2 {
  font-weight: 400;
  font-size: 52px;
  line-height: 78px;
}

.headerIntroTxt h2 span {
  font-weight: 700;
  padding: 8px 12px;
  background-color: #dbeaff;
  border-radius: 12px;
  border-bottom: 6px solid #0068ff;
}

.headerIntroSubTxt {
  margin-top: 8px;
  color: #212121;
  max-width: 48%;
}

.headerIntroSubTxt span {
  font-weight: 700;
}

.headerImage {
  max-width: 50%;
  direction: rtl;
}

.headerImage img {
  background: linear-gradient(151.25deg, rgba(0, 104, 255, 0) 0%, #0068ff 100%);
  width: 56%;
  padding: 16px;
  border-radius: 24px;
}

/* Media queries */

@media only screen and (max-width: 768px) {
    .headerIntroTxt h2 {
        font-size: 32px;
        line-height: 48px;
      }

      .headerIntroSubTxt {
        max-width: 100%;
      }

}

@media only screen and (max-width: 480px) {
    .headerIntroTxt h2 {
        font-size: 28px;
        line-height: 38px;
      }

      .headerIntroSubTxt {
        max-width: 100%;
      }

      .headerIntroTxt h2 span {
        font-weight: 700;
        padding: 2px 6px;
        background-color: #dbeaff;
        border-radius: 6px;
        border-bottom: 4px solid #0068ff;
      }
      
      .headerContainer {
        padding: 40px 0;
      }
}

@media only screen and (max-width: 360px) {
    .headerIntroTxt h2 {
        font-size: 26px;
        line-height: 36px;
      }

}
/* Media queries */