.footer {
  display: flex;
  justify-content: center;
  padding: 0 24px;
  background: #f1f3f6;
  border-top: 1px solid #e0e0e0;
}

.footerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  max-width: 1128px;
  margin: 40px 0;
}

.footerTxt {
  font-family: "Playfair Display", serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #212121;
}

.footerSubTxt p {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #212121;
  text-align: center;
}

.footerSubTxt {
  display: flex;
  gap: 8px;
}

.footerSubTxt span {
  width: 32px;
  height: 32px;
}

.footerSocial {
  display: flex;
  gap: 24px;
  background-color: #0068ff;
  padding: 8px 16px;
  border-radius: 12px;
}

.socialIcon {
  font-size: 32px;
  color: white;
}
